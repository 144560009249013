import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板"
    },
    {
      icon: "fa-columns",
      title: "選單安排",
      subNodes: [
        {
          icon: "",
          to: "/dashboard",
          title: "側邊選單",
          ignore: true
        },
        {
          icon: "",
          to: "/simple-menu/dashboard",
          title: "簡易選單",
          ignore: true
        },
        {
          icon: "",
          to: "/top-menu/dashboard",
          title: "上方選單",
          ignore: true
        }
      ]
    },
    {
      icon: "fa-cog",
      title: "系統管理",
      subNodes: [
        {
          icon: "SettingsIcon",
          to: "configuration",
          title: "系統參數設定"
        },
        {
          icon: "PenToolIcon",
          to: "color",
          title: "顏色管理"
        },
        {
          icon: "Maximize2Icon",
          to: "size",
          title: "尺寸管理"
        },
        {
          icon: "GlobeIcon",
          to: "country",
          title: "產地管理"
        },
        {
          icon: "PackageIcon",
          to: "unit",
          title: "產品單位管理"
        }
      ]
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈"
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },
    {
      icon: "fa-sitemap",
      to: "category/product",
      title: "類別管理",
      subNodes: [
        {
          icon: "CodepenIcon",
          to: "category/product",
          title: "產品分類"
        },
        {
          icon: "RssIcon",
          to: "category/news",
          title: "最新消息分類"
        },
        {
          icon: "UsersIcon",
          to: "category/athleticStar",
          title: "球星分類"
        }
      ]
    },
    {
      icon: "FlagIcon",
      to: "banner",
      title: "廣告管理"
    },
    {
      icon: "ImageIcon",
      to: "album",
      title: "相簿管理"
    },
    {
      icon: "ShoppingBagIcon",
      to: "product",
      title: "產品管理"
    },
    {
      icon: "fa-list",
      to: "attribute",
      title: "屬性管理"
    },
    {
      icon: "RssIcon",
      to: "news",
      title: "最新消息管理"
    },
    {
      icon: "fa-medal",
      to: "athletic-star",
      title: "球星管理"
    },
    {
      icon: "fa-store-alt",
      to: "distributor",
      title: "經銷商管理"
    },
    {
      icon: "fa-book-open",
      to: "catalog",
      title: "型錄管理"
    }
    // "devider",
    // {
    //   icon: "fa-tachometer-alt",
    //   to: "midone-dashboard",
    //   title: "Dashboard"
    // },
    // {
    //   icon: "InboxIcon",
    //   to: "midone-inbox",
    //   title: "Inbox"
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "midone-file-manager",
    //   title: "File Manager"
    // },
    // {
    //   icon: "CreditCardIcon",
    //   to: "midone-point-of-sale",
    //   title: "Point of Sale"
    // },
    // {
    //   icon: "MessageSquareIcon",
    //   to: "midone-chat",
    //   title: "Chat"
    // },
    // {
    //   icon: "FileTextIcon",
    //   to: "midone-post",
    //   title: "Post"
    // },
    // "devider",
    // {
    //   icon: "EditIcon",
    //   to: "midone-crud-data-list",
    //   title: "Crud",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-crud-data-list",
    //       title: "Data List"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-crud-form",
    //       title: "Form"
    //     }
    //   ]
    // },
    // {
    //   icon: "UsersIcon",
    //   to: "midone-users-layout-1",
    //   title: "Users",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-users-layout-1",
    //       title: "Layout 1"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-users-layout-2",
    //       title: "Layout 2"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-users-layout-3",
    //       title: "Layout 3"
    //     }
    //   ]
    // },
    // {
    //   icon: "TrelloIcon",
    //   to: "midone-profile-overview-1",
    //   title: "Profile",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-1",
    //       title: "Overview 1"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-2",
    //       title: "Overview 2"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-3",
    //       title: "Overview 3"
    //     }
    //   ]
    // },
    // {
    //   icon: "LayoutIcon",
    //   to: "midone-wizard-layout-1",
    //   title: "Pages",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-wizard-layout-1",
    //       title: "Wizards",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-blog-layout-1",
    //       title: "Blog",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-pricing-layout-1",
    //       title: "Pricing",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-pricing-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-pricing-layout-2",
    //           title: "Layout 2"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-invoice-layout-1",
    //       title: "Invoice",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-invoice-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-invoice-layout-2",
    //           title: "Layout 2"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-faq-layout-1",
    //       title: "FAQ",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "login",
    //       title: "Login"
    //     },
    //     {
    //       icon: "",
    //       to: "register",
    //       title: "Register"
    //     },
    //     {
    //       icon: "",
    //       to: "error-page",
    //       title: "Error Page"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-update-profile",
    //       title: "Update profile"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-change-password",
    //       title: "Change Password"
    //     }
    //   ]
    // },
    // "devider",
    // {
    //   icon: "InboxIcon",
    //   to: "midone-regular-table",
    //   title: "Components",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-regular-table",
    //       title: "Table",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-regular-table",
    //           title: "Regular Table"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-tabulator",
    //           title: "Tabulator"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-accordion",
    //       title: "Accordion"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-button",
    //       title: "Button"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-modal",
    //       title: "Modal"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-alert",
    //       title: "Alert"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-progress-bar",
    //       title: "Progress Bar"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-tooltip",
    //       title: "Tooltip"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-dropdown",
    //       title: "Dropdown"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-toast",
    //       title: "Toast"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-typography",
    //       title: "Typography"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-icon",
    //       title: "Icon"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-loading-icon",
    //       title: "Loading Icon"
    //     }
    //   ]
    // },
    // {
    //   icon: "SidebarIcon",
    //   to: "midone-regular-form",
    //   title: "Forms",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-regular-form",
    //       title: "Regular Form"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-datepicker",
    //       title: "Datepicker"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-tail-select",
    //       title: "Tail Select"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-file-upload",
    //       title: "File Upload"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-wysiwyg-editor",
    //       title: "Wysiwyg Editor"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-validation",
    //       title: "Validation"
    //     }
    //   ]
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "midone-chart",
    //   title: "Widgets",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-chart",
    //       title: "Chart"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-slider",
    //       title: "Slider"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-image-zoom",
    //       title: "Image Zoom"
    //     }
    //   ]
    // }
  ]
};

export default sitemap;
