import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import category from "./category";
import bannerPosition from "./banner-position";
import banner from "./banner";
import pictureAlbum from "./picture-album";
import picture from "./picture";
import color from "./color";
import athleticStar from "./athletic-star";
import catalogue from "./catalogue";
import comparisonTemplate from "./comparison-template";
import country from "./country";
import equipment from "./equipment";
import product from "./product";
import record from "./record";
import size from "./size";
import tag from "./tag";
import template from "./template";
import unit from "./unit";
import attribute from "./attribute";
import attributeValue from "./attribute-value";
import news from "./news";
import distributor from "./distributor";
const model = new Model("EmptyNext", {
  main,
  configuration,
  role,
  user,
  actionLog,
  category,
  bannerPosition,
  banner,
  pictureAlbum,
  picture,
  color,
  athleticStar,
  catalogue,
  comparisonTemplate,
  country,
  equipment,
  product,
  record,
  size,
  tag,
  template,
  unit,
  attribute,
  attributeValue,
  news,
  distributor
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
